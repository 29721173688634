import layout from '../view/layout'

const router = {
  name: 'course',
  path: 'course',
  component: layout,
  meta: {
    title: '课程',
    ico: 'el-icon-notebook-1',
    authkey: 'course'
  },
  children: [
    {
      name: 'course_package',
      route_name: 'course_package',
      path: 'package',
      component: () => import('../view/course/package'),
      meta: {
        title: '课程包管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'course_package'
      }
    },
    {
      name: 'course_package_edit',
      route_name: 'course_package_edit',
      path: 'package/edit',
      component: () => import('../view/course/packageEdit'),
      meta: {
        title: '课程包编辑',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'course_package'
      }
    },
    {
      name: 'course_order',
      route_name: 'course_order',
      path: 'order',
      component: () => import('../view/course/order'),
      meta: {
        title: '订单列表',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'course_order'
      }
    }
  ]
}

export default router
