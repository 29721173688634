import { fetch } from '../utils/request';

// 基础
const authLogin = (param, callback) => fetch('/admin/1.0/auth/login','POST',param, callback);
const authLogout = (callback) => fetch('/admin/1.0/auth/logout','POST',false, callback);
const myIndex = (callback) => fetch('/admin/1.0/my/index','GET',{}, callback);
const myUpdate = (param, callback) => fetch('/admin/1.0/my/update','POST',param, callback);
const myPrivs = (callback) => fetch('/admin/1.0/my/privs','GET',{}, callback);
const myUpdatePwd = (param, callback) => fetch('/admin/1.0/my/resetpwd','POST',param, callback);

// 文件
const FileUpload = (param, callback) => fetch('/admin/1.0/common/uploadimg','POST',param, callback);
const fileList = (param, callback) => fetch('/admin/1.0/common/filelist','GET',param, callback);
const filedel = (param, callback) => fetch('/admin/1.0/common/filedel','POST',param, callback);

// 系统
const sysDetail = (callback) => fetch('/admin/1.0/sys/info','GET', {}, callback);
const sysEdit = (param, callback) => fetch('/admin/1.0/sys/update','POST', param, callback);

// 用户
const userIndex = (param, callback) => fetch('/admin/1.0/user/index','GET', param, callback);
const userDetail = (param, callback) => fetch('/admin/1.0/user/detail','GET', param, callback);
const userAdd = (param, callback) => fetch('/admin/1.0/user/add','POST', param, callback);
const userEdit = (param, callback) => fetch('/admin/1.0/user/edit','POST', param, callback);
const userDelete = (param, callback) => fetch('/admin/1.0/user/del','POST', param, callback);
const userVisitLog = (param, callback) => fetch('/admin/1.0/user/visitlog','GET', param, callback);
const teacherIndex = (param, callback) => fetch('/admin/1.0/user/teacher','GET', param, callback);
const teacherEdit = (param, callback) => fetch('/admin/1.0/user/teacheredit','POST', param, callback);
const studentIndex = (param, callback) => fetch('/admin/1.0/user/student','GET', param, callback);

// 群组
const groupIndex = (param, callback) => fetch('/admin/1.0/group/index','GET', param, callback);
const groupDetail = (param, callback) => fetch('/admin/1.0/group/detail','GET', param, callback);
const groupAdd = (param, callback) => fetch('/admin/1.0/group/add','POST', param, callback);
const groupEdit = (param, callback) => fetch('/admin/1.0/group/edit','POST', param, callback);
const groupDelete = (param, callback) => fetch('/admin/1.0/group/del','POST', param, callback);

// 菜单
const menuIndex = (param, callback) => fetch('/admin/1.0/menu/index','GET', param, callback);
const menuDetail = (param, callback) => fetch('/admin/1.0/menu/detail','GET', param, callback);
const menuAdd = (param, callback) => fetch('/admin/1.0/menu/add','POST', param, callback);
const menuEdit = (param, callback) => fetch('/admin/1.0/menu/edit','POST', param, callback);
const menuDelete = (param, callback) => fetch('/admin/1.0/menu/del','POST', param, callback);

export default {
  authLogin,
  authLogout,
  myIndex,
  myUpdate,
  myPrivs,
  myUpdatePwd,
  FileUpload,
  fileList,
  filedel,
  sysDetail,
  sysEdit,
  userIndex,
  userDetail,
  userAdd,
  userEdit,
  userDelete,
  userVisitLog,
  teacherIndex,
  teacherEdit,
  studentIndex,
  groupIndex,
  groupDetail,
  groupAdd,
  groupEdit,
  groupDelete,
  menuIndex,
  menuDetail,
  menuAdd,
  menuEdit,
  menuDelete
}
