import layout from '../view/layout'

const router = {
  name: 'news',
  path: 'news',
  component: layout,
  meta: {
    title: '资讯',
    ico: 'el-icon-document',
    authkey: 'news'
  },
  children: [
    {
      name: 'news_index',
      route_name: 'news_index',
      path: 'index',
      component: () => import('../view/news/index'),
      meta: {
        title: '资讯列表',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'news_index'
      }
    },
    {
      name: 'news_edit',
      route_name: 'news_index',
      path: 'edit',
      component: () => import('../view/news/edit'),
      meta: {
        title: '资讯添加',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'news_index'
      }
    },
    {
      name: 'news_menu',
      route_name: 'news_menu',
      path: 'menu',
      component: () => import('../view/news/menu'),
      meta: {
        title: '分类列表',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'news_menu'
      }
    }
  ]
}

export default router
