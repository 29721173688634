import { fetch } from '../utils/request';

// 课程包
const coursePackageIndex = (param, callback) => fetch('/admin/1.0/coursePackage/getList','GET', param, callback);
const coursePackageInfo = (param, callback) => fetch('/admin/1.0/coursePackage/get','GET', param, callback);
const coursePackageEdit = (param, callback) => fetch('/admin/1.0/coursePackage/add','POST', param, callback);
const coursePackageShenhe = (param, callback) => fetch('/admin/1.0/coursePackage/changeStatus','POST', param, callback);
const coursePackageSend = (param, callback) => fetch('/admin/1.0/course/createCoursePackage','POST', param, callback);
const courseOrderIndex = (param, callback) => fetch('/admin/1.0/order/index','GET', param, callback);
const courseOrderPay = (param, callback) => fetch('/admin/1.0/order/pay','POST', param, callback);
const courseOrderFinish = (param, callback) => fetch('/admin/1.0/order/finish','POST', param, callback);
const courseOrderCancel = (param, callback) => fetch('/admin/1.0/order/cancel','POST', param, callback);

export default {
  coursePackageIndex,
  coursePackageInfo,
  coursePackageEdit,
  coursePackageShenhe,
  coursePackageSend,
  courseOrderIndex,
  courseOrderPay,
  courseOrderFinish,
  courseOrderCancel
}
