import layout from '../view/layout'

const router = {
  name: 'user',
  path: 'user',
  component: layout,
  meta: {
    title: '用户',
    ico: 'el-icon-user-solid',
    authkey: 'user'
  },
  children: [
    {
      name: 'user_index',
      route_name: 'user_index',
      path: 'index',
      component: () => import('../view/user/index'),
      meta: {
        title: '用户管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'user_index'
      }
    },
    {
      name: 'user_add',
      route_name: 'user_index',
      path: 'add',
      component: () => import('../view/user/add'),
      meta: {
        title: '用户添加',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'user_index'
      }
    },
    {
      name: 'user_group',
      route_name: 'user_group',
      path: 'group',
      component: () => import('../view/user/group'),
      meta: {
        title: '群组管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'user_group'
      }
    },
    {
      name: 'user_group_add',
      route_name: 'user_group',
      path: 'groupadd',
      component: () => import('../view/user/groupadd'),
      meta: {
        title: '群组添加',
        ico: 'el-icon-c-scale-to-original',
        isShow: false,
        authkey: 'user_group'
      }
    },
    {
      name: 'user_teacher',
      route_name: 'user_teacher',
      path: 'teacher',
      component: () => import('../view/user/teacher'),
      meta: {
        title: '老师管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'user_teacher'
      }
    },
    {
      name: 'user_student',
      route_name: 'user_student',
      path: 'student',
      component: () => import('../view/user/student'),
      meta: {
        title: '学生管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'user_student'
      }
    }
  ]
}

export default router
