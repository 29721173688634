import { fetch } from '../utils/request';

// 新闻
const newsIndex = (param, callback) => fetch('/admin/1.0/news/index','GET', param, callback);
const newsInfo = (param, callback) => fetch('/admin/1.0/news/detail','GET', param, callback);
const newsEdit = (param, callback) => fetch('/admin/1.0/news/edit','POST', param, callback);
const newsDel = (param, callback) => fetch('/admin/1.0/news/del','POST', param, callback);

// 新闻分类
const newsMenuIndex = (param, callback) => fetch('/admin/1.0/news/menu/index','GET', param, callback);
const newsMenuInfo = (param, callback) => fetch('/admin/1.0/news/menu/detail','GET', param, callback);
const newsMenuEdit = (param, callback) => fetch('/admin/1.0/news/menu/edit','POST', param, callback);
const newsMenuDel = (param, callback) => fetch('/admin/1.0/news/menu/del','POST', param, callback);
const newsMenuTree = (param, callback) => fetch('/admin/1.0/news/menu/tree','GET', param, callback);

export default {
  newsIndex,
  newsInfo,
  newsEdit,
  newsDel,
  newsMenuIndex,
  newsMenuInfo,
  newsMenuEdit,
  newsMenuDel,
  newsMenuTree
}
