import layout from '../view/layout'

const router = {
  name: 'person',
  path: 'person',
  component: layout,
  meta: {
    title: '个人中心',
    ico: 'el-icon-s-home',
    authkey: 'home'
  },
  children: [
    {
      name: 'index',
      route_name: 'index',
      path: '',
      component: () => import('../view/index'),
      meta: {
        title: '首页',
        ico: 'el-icon-c-scale-to-original',
        isShow: false
      }
    },
    {
      name: 'person_index',
      route_name: 'person_index',
      path: 'index',
      component: () => import('../view/person/index'),
      meta: {
        title: '个人信息',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'myinfo'
      }
    },
    {
      name: 'person_resetpwd',
      route_name: 'person_resetpwd',
      path: 'resetpwd',
      component: () => import('../view/person/resetpwd'),
      meta: {
        title: '修改密码',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'resetpwd'
      }
    }
  ]
}

export default router
