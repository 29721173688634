import layout from '../view/layout'

const router = {
  name: 'sys',
  path: 'sys',
  component: layout,
  meta: {
    title: '系统',
    ico: 'el-icon-s-tools',
    authkey: 'sys'
  },
  children: [
    {
      name: 'sys_set',
      route_name: 'sys_set',
      path: 'set',
      component: () => import('../view/sys/set'),
      meta: {
        title: '系统设置',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_set'
      }
    },
    {
      name: 'sys_menu',
      route_name: 'sys_menu',
      path: 'menu',
      component: () => import('../view/sys/menu'),
      meta: {
        title: '菜单管理',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_menu'
      }
    },
    {
      name: 'sys_visitlog',
      route_name: 'sys_visitlog',
      path: 'visitlog',
      component: () => import('../view/sys/visitlog'),
      meta: {
        title: '访问日志',
        ico: 'el-icon-c-scale-to-original',
        isShow: true,
        authkey: 'sys_visitlog'
      }
    }
  ]
}

export default router
